@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'Marshena_DEMO';
  src: url('./assets/fonts/Marshena_DEMO.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mail Sans Roman';
  src: url('./assets/fonts/MailSansRoman-Light.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-Light.woff') format('woff');
  font-weight: 300; 
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mail Sans Roman';
  src: local('Mail Sans Roman'), 
  url('./assets/fonts/MailSansRoman-Regular.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mail Sans Roman';
  src:local('Mail Sans Roman'), 
   url('./assets/fonts/MailSansRoman-Medium.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-Medium.woff') format('woff');
  font-weight: 500; /* Normal weight */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mail Sans Roman';
  src: local('Mail Sans Roman'), 
  url('./assets/fonts/MailSansRoman-Bold.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-DemiBold.woff') format('woff');
  font-weight: 600; /* Normal weight */
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Mail Sans Roman';
  src: local('Mail Sans Roman'), 
  url('./assets/fonts/MailSansRoman-DemiBold.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-DemiBold.woff') format('woff');
  font-weight: 700; /* Normal weight */
  font-style: normal;
  font-display: swap;
}


code {
  font-family: 'Mail Sans Roman', sans-serif;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Mail Sans Roman', sans-serif;
}

body {
margin: 0;
font-family:  'Mail Sans Roman', sans-serif;
overflow-x: hidden !important;
background-color: white;
color:#1D2939;


}


@media (max-width:576px){
  body{background-color: #FBFDFF !important;}
}


